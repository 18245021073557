:root {
  --snapshot: #2c3e50;
  --zodiac: #8b9194;

  --bay-view: #6a81a4;
  --child-of-light: #f2f5f8;
  --plaudit: #3a5269;
  --dark: #1a252f;
  --silver-surfer: #737882;
  --blue-reflection: #cad6e2;
  --white: #ffffff;
  --covered-in-platinum: #b9b9b9;
  --compass-blue: #32475c;
  --pearl: #f6f9fa;
  --metroid-red: #fa3c00;
  --washed-black: #212529;
  --azure: #007bff;
  --london-rain: #0056b3;
  --silken-ruby:#e9141d;
  --too-dark-tonight:#0015bc;
  --electric-glow:#fed000;
  --nordic-grass-green:#17aa5c;
  --blue-whale:#1B3147;
  --diamond-black:#2B313C;
  --course-wool:#171B23;
  --frilled-shark:#949DA6;
  --heart-of-ice:#F9FDFF;
  --wash-me:#fafcfd;
  --lily-pond-blue:#516F7D;
  --clear-vision:#e8f1f8;
  --ice-effect:#C2EAFE;
  --blue-bazaar: #007CB9;
  --anti-flash-white: #f2f4f4;
  --kahu-blue:#008ed5;


 --democrat:var(--too-dark-tonight);
 --republican:var(--silken-ruby);
 --libertarian:var(--electric-glow);
 --green-party:var(--nordic-grass-green);


  --border-color: var(--child-of-light);
  --primary: var(--diamond-black);
  --primary-dark: var(--course-wool);
  --primary-light: var(--plaudit);
  --gray: var(--frilled-shark);
  --gray-dark: var(--silver-surfer);

  --border-radius: 16px;
  --border-radius-sm: 10px;
  --border-radius-xs: 6px;

  --gray-light: var(--covered-in-platinum);
  --danger: var(--metroid-red);
  --warning: var(--electric-glow);
  --info: var(--azure);
  --success: var(--nordic-grass-green);

  --base-padding: 10px;

  --compact-panel-height:215px;

  --panel-headline-bg:#FFEC99;

  --header-height: 50px;
  --header-box-shadow: 0px 0px 6px #E6F0FA, 0px 0px 6px #E9EFF2;
  --left-nav-width: 292px;
  --left-nav-width-collapsed: 70px;
  --left-nav-color: var(--gray);
  --left-nav-color-active: var(--white);
  --left-nav-color-disabled: var(--silver-surfer);
  --left-nav-bg: var(--primary);
  --left-nav-item-padding: 17px;
  --left-nav-line-height: 18px;
  --left-nav-item-hover-bg: var(--primary-dark);
  --left-nav-item-active-bg: var(--primary-dark);
  --nav-padding: 0 var(--base-padding);
  --nav-bg: var(--white);
  --nav-menu-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 25%);
  --nav-menu-color: var(--primary-light);

  --btn-primary-bg: var(--primary);
  --btn-primary-bg-hover: var(--primary-dark);
  --btn-primary-border: 1px solid var(--primary);
  --btn-box-shadow: none;

  --btn-white-bg:var(--white);
  --btn-white-hover-bg:var(--anti-flash-white);
  --btn-white-border-color:var(--clear-vision);

  --btn-secondary-bg:var(--silver-surfer);
  --btn-secondary-border: 1px solid var(--primary);
  --btn-secondary-bg-hover: var(--zodiac);

  

  --body-bg: var(--heart-of-ice);
  --body-color: var(--diamond-black);
  --font-family:"Source Sans Pro",  sans-serif;
  --font-size: 13px;

  --card-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  --card-border: 1px solid var(--border-color);
  --card-padding: 12px;

  --form-group-margin: 0 0 var(--base-padding) 0;

  --label-color:var(--lily-pond-blue);

  --entity-datapoint-label-color:var(--label-color);
  --entity-datapoint-bg:var(--wash-me);
  --entity-table-border:1px solid var(--clear-vision);
  --entity-item-border-color:var(--clear-vision);

  --input-box-shadow: none;
  --input-border-color: var(--gray);
  --input-placeholder-color: var(--label-color);
  --input-height: 34px;
  --input-padding: 6px 12px;
  --input-label-margin: 0 0 5px 0;

  --link-color: var(--kahu-blue);
  --link-color-hover: var(--blue-bazaar);

  --link-color-light: var(--gray-light);
  --link-color-dark: var(--light-dark);

  --content-padding: 20px;

  --filter-bg: var(--primary-dark);
  --filter-color: var(--frilled-shark);
  --filter-disabled: var(--lily-pond-blue);
  --filter-cb-border-color:var(--frilled-shark);

  --panel-box-shadow: 0px 4px 14px #e6f0fa, 0px 4px 14px #e9eff2;
  --info-toggle-bg:var(--primary-dark);
  --info-toggle-color:var(--white);

  --subnav-bg: var(--filter-bg);
  --subnav-disabled: var(--filter-disabled);

  --info-bg: var( --primary);
  --info-color: var(--white);

  --breadcrumb-color: var(--compass-blue);
  --breadcrumb-active-color: var(--gray-dark);

  --tab-bg: var(--white);

  --table-row-even-bg:var(--wash-me);
  --table-row-odd-bg:var(--white);
  --table-row-selected-bg:var(--ice-effect);
  --table-row-hover-bg:#e1ebf0;
  --table-header-color:var(--lily-pond-blue);
  --table-actions-border-color:var(--clear-vision);
  --table-actions-location-color:var(--lily-pond-blue);
  --table-actions-sep-color:var(--clear-vision);

  --table-search-input-border:1px solid var(--clear-vision);

  --badge-bg:var(--ice-effect);
  --badge-color:var(--blue-bazaar);

  --wizard-border-color:var(--blue-reflection);
}
