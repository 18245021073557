@import './variables';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import '../../node_modules/@jc99ta/react-base-table/styles.css';
@import '../../node_modules/react-loading-skeleton/dist/skeleton.css';
@import '../../node_modules/react-js-cron/dist/styles.css';

html,
body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  background-color: var(--body-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  height: 100%;
  color: var(--body-color);
  #root {
    height: 100%;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--clear-vision);
}

.dark::-webkit-scrollbar-track {
  background: var(--primary-dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--frilled-shark);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--lily-pond-blue);
}

.notification-container {
  left: 50%;
  right: auto !important;
  transform: translateX(-50%);
  overflow: hidden !important;

  .notification-enter {
    transform: translate3d(0, -100%, 0);
  }

  .notification-enter.notification-enter-active {
    transform: translate3d(0, 0, 0);
  }

  .notification-exit {
    transform: translate3d(0, 0, 0);
  }

  .notification-exit.notification-exit-active {
    transform: translate3d(0, -100%, 0);
  }
}

.tab-content {
  .title-bar {
    border-top-left-radius: 0;
  }
}

.form-select__loading-indicator {
  position: absolute;
  right: 35px;
}

.form-select__option {
  .option__subtext {
    font-size: 11px;
    display: block;
    color: var(--gray-dark);
  }
  &:hover {
    .option__subtext {
      color: var(--white);
    }
  }
}

.shadow-1 {
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.6);
}

.radius-1 {
  border-radius: 4px;
}

textarea {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  height: auto !important;
  display: block;
  width: 100%;
  padding: var(--input-padding);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:focus,
  &:focus-visible,
  &:focus-within {
    box-shadow: var(--input-box-shadow);
    border: 1px solid #86b7fe !important;
    outline: none;
  }
}
